import {LinkService} from '../../i18n/link.service'
import {PartnerItemFieldsFragment, PartnerSectionFieldsFragment} from '../../../../__generated__/datocms.types'
import {PartnerItem, PartnerSection} from '../types/partner-section.types'
import {sanitizePathUrl, validateDatoCmsTypes} from './common.serde'
import {parseAsset2} from './asset.serde'
import {PUBLIC_ROUTES} from '../../../shared/routing/public.routes'
import {ApplicationRoute, LOCALIZED_ROUTE_TOKEN} from '../../../craft/utils/application.route'

export function parsePartnerSection(
  linkService: LinkService,
  section?: PartnerSectionFieldsFragment,
): PartnerSection | undefined {
  if (!section) {
    return undefined
  }
  return {
    __type: 'PartnerSection',
    title: section.title!,
    plot: section.plot,
    items: parsePartnerItemCollection(linkService, section.items),
  }
}

function parsePartnerItemCollection(linkService: LinkService, items: PartnerItemFieldsFragment[]): PartnerItem[] {
  return items.map(item => parsePartnerItem(linkService, item as PartnerItemFieldsFragment)) ?? []
}

function parsePartnerItem(linkService: LinkService, e: PartnerItemFieldsFragment): PartnerItem {
  const entry = validateDatoCmsTypes(e)

  return {
    title: entry.title,
    description: entry.description,
    logo: parseAsset2(entry.logo),
    targetPath: entry?.target ? parsePartnerItemTargetPath(linkService, entry) : undefined,
  }
}

function parsePartnerItemTargetPath(linkService: LinkService, e: PartnerItemFieldsFragment) {
  const entry = validateDatoCmsTypes(e)
  const target = validateDatoCmsTypes(entry.target)

  switch (target.__typename) {
    case 'CategoryLandingPageModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.CATEGORY_LANDING_PAGE, {
        routeParams: {pageSlug: target.slug},
      })
    case 'ArticleModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.ARTICLE_PAGE, {routeParams: {pageSlug: target.slug}})
    case 'StaticPageModelRecord': {
      const staticRoute = new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/${sanitizePathUrl(target.path)}`)
      return linkService.localizedPathTo(staticRoute)
    }
  }
}
